import React, { Component } from 'react';
export default class Footer extends Component {
  render() {
    return (
      <section id="contact">
        <ul className="social">
          <li>
            <a href="https://www.linkedin.com/in/markridley/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
          </li>
          <li>
            <a href="https://twitter.com/mark_ridley" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter-square"></i></a>
          </li>
          <li>
            <a href="https://medium.com/@Mark_Ridley" target="_blank" rel="noopener noreferrer"><i className="fab fa-medium"></i></a>
          </li>
        </ul>
      </section>
    );
  }
}