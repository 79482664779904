import React from 'react'

export default () => (
    <div id="engagements" class="main">
        <div className="row banner">
            <div className="banner-text">
                <div className="three columns header-col">
                    <h1>How we engage with clients</h1>
                </div>
            </div>
            <div className="nine columns main-col">
                <a name="startups" href="#"></a> 
                <h2>For Startups &amp; Scaleups</h2>
                <p>Startups move fast. As the team, product and market changes, a <strong>Tech &amp; Strategy Diagnosis</strong> can help identify pain points and opportunities. This two day workshop involves your key staff in making a clear set of prioritised recommendations to act on.</p>
                <p>With all of the stresses of a young startup, moving quickly is crucial to outrun burn rate. With an <strong>Advisory</strong> engagement, you can benefit from the experience of other similar startups that have had to make the same choices. A series of <strong>Playbooks</strong> developed will help you move faster than the competition.</p>
                <hr />
                <a name="established" href="#"></a>
                <h2>For established businesses</h2>
                <p>Larger and more established companies often feel like they're not moving fast enough and want to act more like startups. A disciplined approach to strategy, investment budgeting and portfolio management is important to unlock the agility of larger businesses.</p>
                <p><strong>The 4 Hour Strategy Sprint</strong> is a workshop designed expressly to force businesses into a leaner, more decisive strategic process, stripping away the waste of annual strategic management processes.</p>
                <p>For many large businesses, the process of identifying and freeing resource to work on new product and business lines can be a challenge. The <strong>New product and proposition design process</strong> is an advisory service, assisting existing teams in your business through an entrepreneurial process based on MIT's battle-tested Disciplined Entrepreneurship approach</p>
                <p>For companies in need of technical leadership, we can offer <strong>CTO Advisory support</strong>. With a number of businesses we have helped steady the ship in times of leadership transition, assisted with the selection of your incoming CTOs, and regularly mentor first-time CTOs from within, or outside, the organisation.</p> 
                <hr />
                <a name="established" href="#"></a>
                <h2>For Investors</h2>
                <p>Venture Capital and Private Equity companies manage complex portfolios of visionary founder teams. Our <strong>Technology Review</strong> provides recommendations for companies and private individuals who are considering investment or acquisition opportunities. <strong>Venture Support</strong> services ensure investors and startup founders have the basics taken care of, with access to a number of best-practice <strong>playbooks</strong>.</p>
            </div>
        </div>
    </div>
)