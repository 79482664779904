import React from 'react';

export default () => (
    <div id="services" class="main">
        <div className="row banner">
            <div className="banner-text">
                <div className="three columns header-col">
                    <h1>Service Portfolio</h1>
                </div>
            </div>
            <div className="nine columns main-col">
                <a name="diagnosis" href="#" href="#"></a>
                <h2>Technology Review &amp; Recommendations</h2>
                <p>For startup founders, existing tech teams and investors, it&apos;s sometimes useful to bring an external perspective on the opportunities and challenges inside your organisation. Our <strong>one and two day workshops</strong> are designed to quickly pinpoint the problems that are blocking your organisation, and give a small number of recommendations on how to remedy them and improve.</p>
                <hr />
                <a name="advisory" href="#"></a>
                <h2>CTO Advisory &amp; Mentoring</h2>
                <p>CTOs aren&apos;t born, they&apos;re built through years of experience. Building the necessary skills to encourage a new generation of technology leaders is a critical part of supporting technnology businesses of the future. A familiar engagement for us is to offer an <strong>interim CTO</strong> to your team, work with the CEO to identify the <a href="https://medium.com/swlh/what-flavour-cto-does-your-business-need-d86e8153fe6e" target="new">type of CTO that you need</a>, assist with the selection of the right individual and then support them as they grow into the role.</p>
                <p>It&apos;s not just new CTOs that need support. We also regularly support experienced CTOs who benefit from having a trusted peer, whether working with new technology, unfamiliar projects or simply as an opportunity to sense check ideas.</p>
                <hr />
                <a name="transformation" href="#"></a>
                <h2>Business &amp; Technology Transformation</h2>
                <p>The process of adopting new technologies is, at its heart, a people problem. Buzzwords like Artificial Intelligence (AI), Machine Learning (ML), Robotic Process Automation (RPA), Microservices and Business Intelligence (BI), are opaque and drive an often unneccessary fear of being left behind. Understanding when a new technology is useful and offers a distinct business advantage can be difficult, especially for entrenched teams, with little time to experiment.</p>
                <p>Understanding when, and how, to adopt new technology often requires a brave decision to move away from legacy systems. Existing teams can feel threatened and unsupported. With our experience across industries, working with large enterprises and young startups, we can offer support for companies to move through these decisions, building a clear and effective plan for change, with great sensitivity to the real business outcomes.</p>
                <hr />
                <a name="innovation" href="#"></a>
                <h2>New Product &amp; Proposition Development</h2>
                <p>Developing a new go-to market proposition is a tough process that requires a cross-functional team of analysts, techies, designers, marketeers and visionaries. Following MIT&apos;s Disciplined Entreprenurship approach, we coach a team of your existing staff in how to <a href="https://medium.com/swlh/the-two-laws-of-building-products-74880772c678" target ="new">design a product that can be built, and once built can be sold.</a></p>
                <hr />
                <a name="teams" href="#"></a>
                <h2>Building Highly Effective Digital Teams</h2>
                <p>Hiring processes. Objective setting. Performance management. Agile. Continuous delivery. Excellent teams need a blend of processes aimed at supporting people, and hard processes which support effective product development. With years of experience developing both large and small teams, centrally located or completely remote, we can offer our best practice approach to team performance, from OKRs to DevOps.</p>
                <hr />
                <a name="investors" href="#"></a>
                <h2>Investor Services</h2>
                <p>Working with venture capital, private equity and family offices, our Investor support services start with a lightweight diagnosis of potential investments, and often lead to ongoing venture support. This can take the form of ongoing advisory with existing teams, support for the board or even support for full restructuring initiatives.</p>
                <hr />
                <a name="strategy" href="#"></a>
                <h2>Strategic Consulting</h2>
                <p>There is no such thing as technology stratgey; there&apos;s only a business strategy and a technical roadmap. Often, the first task that we&apos;re set by a CEO is to &apos;fix the tech&apos;. It normally doesn&apos;t take long to discover that the problem with tech is an <a href="https://medium.com/swlh/a-little-less-strategy-a-little-more-action-why-most-successful-companies-fail-at-strategy-6d4d3d0931ab" target="new">absence of clear business strategy</a>. The 4 Hour Strategy Sprint is a starting point for creating a clear plan inside an organisation, but we often extend this process to a long term relationship to advise executive teams on the overall strategy, most often in high tech firms and startups.</p>
            </div>
        </div>
    </div>
)