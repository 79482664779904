 import React, { Component } from 'react';
export default class Intro extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="intro" class="main">
         <div className="row banner">
            <div className="banner-text">
            <div className="three columns header-col">
                <img className="logo" src="/images/ridley-logo-dark.png" alt="Ridley Industries Logo"></img>
            </div>
            <div className="nine columns main-col">
              <h1>Product &amp; Technology Leadership <br /> as-a-service</h1>
              <h2>Helping startups to scale,<br />established businesses to be more dynamic<br />and investors to make informed decisions</h2>
              <p>Working with startup founders, executive teams and established technology functions, we help businesses align their strategy and structure with their business goals.</p>
              <p>Our leading UK CTOs and technologists help teams create a clear plan for how technology can differentiate their business, developing the necessary culture, behaviour and operational capabilites to succeed.</p>
              <hr/>
              <p>Learn more about how we <a href="/services">engage with customers</a>, and Mark Ridlley&apos;s <a href="/bio">background and experience</a></p>
            </div>
           </div>
         </div>
        </div>
      </React.Fragment>
    );
  }
}