import React from 'react'

export default () => (
    <div id="bio" class="main">
        <div className="row banner">
            <div className="banner-text">
                <div className="three columns header-col">
                    <h1>Biography</h1>
                </div>
            </div>
            <div className="nine columns main-col">                
                <p><strong>Mark Ridley</strong> is an entrepreneur and technologist working with startups and corporates to ensure that technology is used creatively and effectively to support their strategy. Recognised as one of the top ten CIOs in the UK, he writes and speaks regularly about the use of technology, agile methodologies and digital working.</p>
                <hr/>
                <p>Mark was the technical co-founder of reed.co.uk, the largest job site in the UK, where he served as technology director during its growth from four people to over 350. At Reed, he created the internal incubator, Monday Labs, to seed new business ideas, before leaving to join Accenture to help establish their new innovation hub in Dublin.</p>
                <hr/>
                <p>From 2017, Mark served as Group CTO of venture builder Blenheim Chalcot, working with CEOs and CTOs across the portfolio on product and technology strategy. Now at Ridley Industries he advises executive teams, investors and company boards in industries as diverse as healthcare, education, financial services and publishing.</p>
                <hr/>
                <p>High Res profile photos are <a href="https://photos.app.goo.gl/frUjSWFk3ca2R9B66" target="new">available here</a></p>
            </div>
        </div>
    </div>
)