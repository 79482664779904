import React, { Component } from 'react';
export default class Header extends Component {
  render() {
    return (
      <React.Fragment>
      <header id="tnav">
         <nav id="nav-wrap">
            <ul id="nav" className="nav">
               <li><a href="/#home">ridley.co</a></li>
               <li><a href="/services">Services</a></li>
               <li><a href="/engagements">How we work</a></li>
               <li><a href="/testimonials">Testimonials</a></li>
               <li><a href="/bio">Biography</a></li>
            </ul>
         </nav>
      </header>
      </React.Fragment>
    );
  }
}