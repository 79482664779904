import React from 'react';
import siteData from '../siteData';

export default () => (
  <div id="testimonials" class="main">
  <div className="row banner">
      <div className="banner-text">
          <div className="three columns header-col">
              <h1>Testimonials</h1>
          </div>
      </div>
      <div className="nine columns main-col" >    
        <ul className="slides">
          {
            siteData.testimonials && siteData.testimonials.map((item)=>{
              return(
                <li key={item.name}>
                  <blockquote>
                    <img src={item.logo} className="client" alt="Client Logo"></img>
                    <p>&ldquo;{item.description}&rdquo;</p>
                    <cite>{item.name}</cite>
                  </blockquote>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  </div>
  );