let siteData = {
    "imagebaseurl":"",
    "name": "Product & Technology Strategy",
    "role": "Technology, Product & Strategy Advisory",
    "linkedinId":"markridley",
    "twitterid": "@mark_ridley",
    "header": "Product & Technology Strategy",
    "introContent": [
      {
        "name":"1",
        "text": "Working with startup founders, executive teams and established technology functions, I help businesses understand how to align their technology strategy and structure with their business goals."
      },
      {
        "name":"2",
        "text": "Together, we create a clear plan for how technology can differentiate your business, developing the necessary culture, behaviour and processes to succeed."
      }
    ]
    ,
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/markridley/",
          "className":"fab fa-linkedin-in"
        },
        {
          "name":"twitter",
          "url":"http://twitter.com/mark_ridley",
          "className":"fab fa-twitter"
        },
        {
          "name":"medium",
          "url":"http://twitter.com/mark_ridley",
          "className":"fab fa-medium-m"
        }
      ],
    "address":"London, United Kingdom",
    "website":"https://www.ridley.co",
    "testimonials":[
      {
        "logo": "/images/cera.png",
        "description":"Mark was instrumental in developing our technology roadmap, strategy and team. He is a seasoned technology leader with invaluable experience and skills.",
        "name":"Mahiben Maruthappu, CEO, Cera Care"
      }
    ]
  }
  
  export default siteData