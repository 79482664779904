import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import siteData from './siteData';
import Home from './views/Home';
import Bio from './views/Bio';
import Services from './views/Services';
import Testimonials from './views/Testimonials';
import NoMatch from './views/NoMatch';
import Engagements from './views/Engagements';

const routes = [
  {
    title: 'Home',
    path: '/',
    component: Home,
    exact: true
  }, {
    title: 'Bio',
    path: '/bio',
    component: Bio,
    exact: true
  }, {
    title: 'Services',
    path: '/services',
    component: Services,
    exact: true
  }, {
    title: 'Engagements',
    path: '/engagements',
    component: Engagements,
    exact: true
  }, {
    title: 'Testimonials',
    path: '/testimonials',
    component: Testimonials,
    exact: true
  }
]

class App extends Component {
  render() {
    return (
      <Router>
        <Header siteData={siteData}/>
        <Switch>
          {routes.map((route, i) => (
            <Route key={i} {...route} />
          ))}
          <Route component={NoMatch} />
        </Switch>
        <Footer siteData={siteData}/>
    </Router>
    );
  }
}

export default App;